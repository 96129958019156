import { type Context } from './types/general'

interface ContextConfiguration {
  UseKftContext: () => Context
}

// eslint-disable-next-line import/no-mutable-exports
let UseKftContext = () => ({}) as Context

const configureContext = (config: ContextConfiguration) => {
  UseKftContext = config.UseKftContext || UseKftContext
}

const generateContext = (factoryParams) => {
  const kftContext = UseKftContext()
  if (factoryParams.provide) {
    return { ...kftContext.$kft, ...factoryParams.provide(kftContext.$kft) }
  }

  return kftContext.$kft
}

export { generateContext, UseKftContext, configureContext }
