import { withTrailingSlash } from 'ufo'
import { useSubdomain } from '~/composables/useSubdomain'

export const useFullLocalizedUrl = (alreadyLocalized: boolean = false) => {
  return (path: string, includeSubdomain: boolean = false) => {
    const subdomain = useSubdomain()
    const config = useRuntimeConfig()
    const { baseUrl } = config.public
    const localePath = useLocalePath()

    path = alreadyLocalized ? path : localePath(path)
    const url = new URL(path, baseUrl)

    if (includeSubdomain && subdomain) {
      url.host = `${subdomain.value}.${url.host}`
    }

    return withTrailingSlash(url.toString(), true)
  }
}
