<template>
  <div>
    <NuxtLazyHydrate when-idle>
      <NuxtLoadingIndicator color="#4ab5bd" :throttle="0" />
    </NuxtLazyHydrate>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
import { useCheckForAppUpdates } from '~/composables/useCheckForAppUpdates'
const { checkForAppUpdates } = useCheckForAppUpdates()

onMounted(() => {
  usePollingWhenTabActive(checkForAppUpdates, 60 * 60 * 1000)
  window.addEventListener(
    'pageshow',
    function (evt) {
      if (evt.persisted) {
        setTimeout(function () {
          window.location.reload()
        }, 10)
      }
    },
    false
  )
})
</script>
