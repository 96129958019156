export const useCheckForAppUpdates = () => {
  const { t } = useI18n()

  const promptIfWantsToReload = (bypassPrompt = false) => {
    if (!bypassPrompt) {
      if (!window.confirm(t('general.reloadPrompt'))) {
        return false
      }
    }
    return true
  }

  const checkForAppUpdates = (ev?: Event) => {
    if (!process.client) return

    const timestamp = useState<number>(undefined)
    const promise = ref<Promise<unknown> | undefined>(undefined)
    const latestTimestamp = ref<number | undefined>(undefined)
    const hasNewVersion = computed(() => {
      return (
        timestamp.value &&
        latestTimestamp.value &&
        latestTimestamp.value > timestamp.value
      )
    })

    if (!promise.value) {
      promise.value = $fetch<{ timestamp: number }>(
        '/_nuxt3/builds/latest.json',
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
      )
        .then((data) => {
          if (!timestamp.value) {
            timestamp.value = data.timestamp
          }
          latestTimestamp.value = data.timestamp

          return data.timestamp
        })
        .catch((e) => {
          console.error('Failed to check for app updates', e)
        })
        .finally(() => {
          if (hasNewVersion.value) {
            if (promptIfWantsToReload(Boolean(ev))) {
              timestamp.value = latestTimestamp.value || timestamp.value
              window.location.reload()
            }
          }
        })
    }

    return promise.value
  }

  return {
    checkForAppUpdates,
  }
}
