/** @name convertISO2Locale
 ** @description Transforms a internal locale (de-de) into the ISO2 format (de)
 ** @param locale user's locale, internal format
 **/
const convertISO2Locale = (locale: string): string => {
  const ISO2LocaleParts = locale.split('-')

  return ISO2LocaleParts[0] || 'de'
}

const convertLocaleIntoBCP47 = (locale: string): string => {
  const ISO2LocaleParts = locale.split('-')

  return ISO2LocaleParts.length > 1 ? `${ISO2LocaleParts[0]}-${ISO2LocaleParts[1].toUpperCase()}` : 'de-DE'
}

/** @name fnGetAvailableLanguages
 ** @desc Returns the list of available languages in Konfetti **/
const fnGetAvailableLanguages = (i18n): any => {
  const context = UseKftContext()
  const i18nInstance = i18n ?? context?.i18n
  return {
    en_US: i18nInstance?.t('general.languages.english'),
    de_DE: i18nInstance?.t('general.languages.german'),
    // fr_FR: context.i18n.t('general.languages.french'),
    // es_ES: context.i18n.t('general.languages.spanish'),
    // ru_RU: context.i18n.t('general.languages.russian'),
    // it_IT: context.i18n.t('general.languages.italian')
  }
}

export { fnGetAvailableLanguages, convertISO2Locale, convertLocaleIntoBCP47 }
