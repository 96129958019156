import parsedRedirects from './redirect-list.json'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) return

  const path = to.fullPath

  if (path && parsedRedirects[path] !== undefined) {
    return navigateTo(
      { path: parsedRedirects[path].page },
      { redirectCode: parsedRedirects[path].code }
    )
  }
})
