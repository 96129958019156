export const usePollingWhenTabActive = (
  cb: (ev?: Event) => void,
  interval = 10000
) => {
  const timeout = ref<number | undefined>(undefined)
  const blurTimestamp = ref<number | undefined>(undefined)

  const poll = () => {
    if (document.hidden) return
    if (!timeout.value) {
      timeout.value = setInterval(cb, interval)
    }
  }

  const handleBlur = () => {
    blurTimestamp.value = Date.now()
    if (timeout.value) {
      clearInterval(timeout.value)
      timeout.value = undefined
    }
  }

  const handleFocus = (ev: Event) => {
    cb(ev)
    poll()
  }

  onMounted(() => {
    cb()

    poll()

    window.addEventListener('blur', handleBlur, false)
    window.addEventListener('focus', handleFocus, false)
  })

  onUnmounted(() => {
    if (timeout.value) {
      clearInterval(timeout.value)
    }
  })
}
