import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry, appEnv },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  const getTraceSampleRate = () => {
    if (appEnv === 'development' || sentry.environment === 'development') {
      // Don't send local errors to sentry
      return 0
    }

    return parseFloat(sentry.sampleRate) || 0.01
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
      }),
    ],

    tracesSampleRate: getTraceSampleRate(),

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://gokonfetti.com',
      'https://front-hml.gokonfetti.com',
    ],

    replaysSessionSampleRate: getTraceSampleRate(),
    replaysOnErrorSampleRate: getTraceSampleRate(),
  })
})
