<template>
  <div
    v-if="isOpen"
    class="flex justify-between rounded-md p-4"
    :class="typeClasses"
  >
    <div class="flex items-center">
      <component
        :is="icons[type] || 'div'"
        v-if="!hideIcon"
        height="30"
        width="30"
        :class="iconClasses"
      />
      <div>
        <div v-if="title" class="ml-3 mb-2 font-medium text-sm leading-4">
          {{ title }}
        </div>
        <slot name="content">
          <div
            v-if="content"
            class="ml-3 font-normal text-sm leading-4 text-gray-700"
          >
            {{ content }}
          </div>
        </slot>
      </div>
      <div v-if="closeCard" class="-mt-4 -mr-2 cursor-pointer">
        <span class="rotate-45 block text-lg" @click="handleClose">+</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconExclamationTriangleFill } from '#components'
import IconExclamationOctagonFill from '~/components/icons/IconExclamationOctagonFill.vue'

const props = withDefaults(
  defineProps<{
    title: string
    content: string
    closeCard: boolean
    hideIcon: boolean
    type: 'info' | 'success' | 'error' | 'warning'
  }>(),
  {
    title: '',
    content: '',
    hideIcon: false,
    closeCard: false,
    type: 'warning',
  }
)

const typeClasses = {
  'bg-info-light': props.type === 'info',
  'bg-danger-light ': props.type === 'error',
  'bg-lime-100': props.type === 'success',
  'bg-warning-light': props.type === 'warning',
}

const icons = {
  info: 'info-circle',
  error: IconExclamationTriangleFill,
  success: 'check-circle',
  warning: IconExclamationOctagonFill,
}

const iconClasses = {
  'bg-info-light text-success': props.type === 'info',
  'bg-danger-light text-danger': props.type === 'error',
  'bg-lime-100 text-gray-700': props.type === 'success',
  'bg-warning-light text-yellow-500': props.type === 'warning',
}

const isOpen = ref(true)
const handleClose = () => {
  isOpen.value = false
}
</script>

<style lang="postcss" scoped>
&__close-button {
  margin-top: -15px;
  span {
    cursor: pointer;
    font-size: 1.2rem;
  }
}
</style>
