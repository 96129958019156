import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}