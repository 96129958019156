import type { DataLayerObject, TrackEventOptions } from '@gtm-support/core'

export const useKftGtm = (): any => {
  const config = useRuntimeConfig()
  const gtm = useGtm()
  const isNotProduction = config.public.appEnv !== 'production'

  if (isNotProduction) {
    gtm?.debugEnabled(false)
    gtm?.debug(false)
  }

  const trackEvent = isNotProduction
    ? (args: TrackEventOptions = {}) => {
        console.log(
          '%cGTM',
            'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;',
            args
          )
      }
    : gtm?.trackEvent

  const pushToDataLayer = (args: TrackEventOptions = {}) => {
    if (isNotProduction) {
        console.log(
          '%cGTM',
            'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;',
            args
          )
    } else if (process.client && gtm.enabled()) window.dataLayer?.push(args)
  }

  const enable = isNotProduction
    ? (cb: (enabled?: boolean, source?: string) => void) => {}
    : gtm?.enable

  return {
    ...(gtm && gtm),
    trackEvent,
    pushToDataLayer,
    enable,
  }
}
