import revive_payload_client_y1JCC9BC9v from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k7vHvCt2jD from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Bo6WORoXDc from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gP19bLjyjA from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vsCarwy1R8 from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vnkP5kVXLf from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_me1DNLlpKp from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_bg2cLZDZ4A from "/app/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_rollup@4.27.2_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YqLUWMmA8o from "/app/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_rollup@4.27.2_vue@3.5.13/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_Bh9cEPcx9i from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.14.159_rollup@4.27.2_vue@3.5.13/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_YzM6L5n2cc from "/app/node_modules/.pnpm/@nuxtjs+i18n-edge@8.3.1-28565891.16f9309_rollup@4.27.2_vue@3.5.13/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_y1JCC9BC9v,
  unhead_k7vHvCt2jD,
  router_Bo6WORoXDc,
  payload_client_gP19bLjyjA,
  navigation_repaint_client_vsCarwy1R8,
  check_outdated_build_client_vnkP5kVXLf,
  chunk_reload_client_me1DNLlpKp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bg2cLZDZ4A,
  plugin_YqLUWMmA8o,
  plugin_Bh9cEPcx9i,
  i18n_YzM6L5n2cc,
  plugin_wy0B721ODc,
  sentry_client_shVUlIjFLk
]