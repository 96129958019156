import { withoutTrailingSlash } from 'ufo'
import regex from '~/composables/useFormValidation/validators/regex'

export default defineNuxtRouteMiddleware((to, from) => {
  const legacyRoutes = [
    '/checkout',
    '/review/.*',
    '/login',
    '/account',
    '/account/general-info',
    '/account/user-info',
    '/account/orders',
    '/account/referral-info',
    '/auth/google/callback',
    '/auth/facebook/callback',
    '/order-success',
    '/password-reset',
    '/login/forgot-password',
    '/terms-supplier',
    '/sitemap',
    '/faq',
    '/embedded/.*',
  ]

  const toPathWithoutLocale = withoutTrailingSlash(
    to.path.replace(/^\/[a-z]{2}-[a-z]{2}\//, '/')
  )

  // check if to.path matches any of the legacy routes regex
  const isLegacyRoute = legacyRoutes.some((route) => {
    const regexStr = `^${withoutTrailingSlash(route).replace(
      /:\w+\(\)/g,
      '.*'
    )}$`
    const regex = new RegExp(regexStr)
    return regex.test(toPathWithoutLocale)
  })

  if (isLegacyRoute) {
    const fullLocalizedUrl = useFullLocalizedUrl(true)
    const newUrl = new URL(fullLocalizedUrl(to.fullPath))

    const config = useRuntimeConfig()
    const bookingSolution = useSubdomain()

    if (config.public.appEnv === 'development') {
      newUrl.host = `gokonfetti.com`
      newUrl.port = ''
    }

    if (bookingSolution.value) {
      newUrl.host = `${bookingSolution.value}.${newUrl.host}`
    }

    return navigateTo(newUrl.href, { external: true, redirectCode: 301 })
  }
})
