import nth from 'lodash/nth'
import type {
  MultipleQueriesResponse,
  SearchResponse,
} from '@algolia/client-search'
import type { AlgoliaEventItem } from '~/composables/types/algolia'

type AlgoliaResults = Ref<MultipleQueriesResponse<AlgoliaEventItem> | null>

const PREFIX = 'useAlgoliaEventApi'

export const eventSectionComposableFactory =
  (index: number) => (algoliaResults: AlgoliaResults) => {
    const queryId = ref()
    const data = ref()

    watchEffect(() => {
      const result = nth(algoliaResults.value?.results, index)

      queryId.value = (result as SearchResponse<AlgoliaEventItem> | undefined)
        ?.queryID
      data.value =
        (result as SearchResponse<AlgoliaEventItem> | undefined)?.hits || []
    })
    return {
      queryId,
      data,
    }
  }
