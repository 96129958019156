import { type RouteLocationNormalized } from 'vue-router'

const mappingRemainingRoutes = (routeToName: string) => {
  if (!routeToName) return 'other'

  const simpleRouteName = routeToName.slice(0, -8)

  const mapping: Record<string, string> = {
    'e-event': 'event',
    'c-categorySlug': 'category',
    'p-permalink': 'partner',
    giftcard: 'giftcard',
    home: 'homepage',
    search: 'search',
  }

  // eslint-disable-next-line no-prototype-builtins
  if (mapping.hasOwnProperty(simpleRouteName)) {
    return mapping[simpleRouteName]
  } else {
    return 'other'
  }
}

export const getGtmContentGroup = (routeTo: RouteLocationNormalized) => {
  if (routeTo?.meta?.contentGroup) {
    return routeTo.meta.contentGroup
  } else if (routeTo?.meta[0]?.contentGroup) {
    // for some reason the meta object changes format between ssr and csr
    return routeTo.meta[0].contentGroup
  } else {
    return mappingRemainingRoutes(routeTo.name)
  }
}
