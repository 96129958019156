import type { RouterConfig } from '@nuxt/schema'
import { subdomainRoutes } from '~/utils/subdomainRoutes'

export default <RouterConfig>{
  trailingSlash: true,
  scrollBehaviorType: 'smooth',
  scrollBehavior(to, from, savedPosition, ...rest) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 5,
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
  },
  strict: true,
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => [...subdomainRoutes(_routes)],
}
